<template>

  <div>
    <h1>System Administration</h1>
    <div id="component-breadcrumbs">
      <b-breadcrumb
        class="breadcrumb-slash"
        :items="breadcrumbItems"
      />
    </div>
    <div>


      <div
        class="row"
        style="margin-bottom: 8px;"
      >
        <div class="col-6">
          <!-- add button -->
          <div>
            <div class="col-4 pr-0 pl-0 D-flex justify-space-arround">
              <b-button
                block
                variant="primary"
                style="justify-content: space-around; display: flex; border-radius: 0"
                @click="openAddPermSetForm()"
              >
                <span>Add Permission Set</span>
              </b-button>
            </div>
          </div>
        </div>
        <div class="col-6">
          <!-- search input -->
          <div class="custom-search d-flex justify-content-end">
            <b-form-group>
              <div class="d-flex align-items-center">
                <label class="mr-1">Search</label>
                <b-form-input
                  v-model="searchTerm"
                  placeholder="Search"
                  type="text"
                  class="d-inline-block"
                />
              </div>
            </b-form-group>
          </div>
        </div>
      </div>


      <!-- table -->
      <vue-good-table
        :columns="columns"
        :rows="rows"
        :rtl="direction"
        :search-options="{
          enabled: true,
          externalQuery: searchTerm }"
        :pagination-options="{
          enabled: true,
          perPage:pageLength
        }"
      >
        <template
          slot="table-row"
          slot-scope="props"
        >
          <!-- Column: Name -->
          <span
            v-if="props.column.field === 'fullName'"
            class="text-nowrap"
          >
            <b-avatar
              :src="props.row.avatar"
              class="mx-1"
            />
            <span class="text-nowrap">{{ props.row.fullName }}</span>
          </span>

          <!-- Column: Status -->
          <span v-else-if="props.column.field === 'status'">
            <b-badge :variant="statusVariant(props.row.status)">
              {{ props.row.status }}
            </b-badge>
          </span>

          <!-- Column: Action -->
          <span v-else-if="props.column.field === 'action'">
            <span>
              <b-dropdown
                variant="link"
                toggle-class="text-decoration-none"
                no-caret
              >
                <template v-slot:button-content>
                  <feather-icon
                    icon="MoreVerticalIcon"
                    size="16"
                    class="text-body align-middle mr-25"
                  />
                </template>
                <b-dropdown-item @click="editPermissionSet(props.row)">
                  <feather-icon
                    icon="Edit2Icon"
                    class="mr-50"
                  />
                  <span>Edit</span>
                </b-dropdown-item>
                <b-dropdown-item @click="copyPermissionSet(props.row)">
                  <feather-icon
                    icon="CopyIcon"
                    class="mr-50"
                  />
                  <span>Copy</span>
                </b-dropdown-item>
                <b-dropdown-item @click="deletePermissionSetWithPrompt(props.row)">
                  <feather-icon
                    icon="TrashIcon"
                    class="mr-50"
                  />
                  <span>Delete</span>
                </b-dropdown-item>
              </b-dropdown>
            </span>
          </span>

          <!-- Column: Common -->
          <span v-else>
            {{ props.formattedRow[props.column.field] }}
          </span>
        </template>

        <!-- pagination -->
        <template
          slot="pagination-bottom"
          slot-scope="props"
        >
          <div class="d-flex justify-content-between flex-wrap">
            <div class="d-flex align-items-center mb-0 mt-1">
              <span class="text-nowrap ">
                {{ props.total }} entries
              </span>
              <b-form-select
                v-model="pageLength"
                :options="['20','50','100']"
                class="mx-1"
                @input="(value)=>props.perPageChanged({currentPerPage:value})"
              />
              <span class="text-nowrap">per page</span>
            </div>
            <div>
              <b-pagination
                :value="1"
                :total-rows="props.total"
                :per-page="pageLength"
                first-number
                last-number
                align="right"
                prev-class="prev-item"
                next-class="next-item"
                class="mt-1 mb-0"
                @input="(value)=>props.pageChanged({currentPage:value})"
              >
                <template #prev-text>
                  <feather-icon
                    icon="ChevronLeftIcon"
                    size="18"
                  />
                </template>
                <template #next-text>
                  <feather-icon
                    icon="ChevronRightIcon"
                    size="18"
                  />
                </template>
              </b-pagination>
            </div>
          </div>
        </template>
      </vue-good-table>
    </div>
    <b-modal
      id="modal-delete-permissionset"
      ok-only
      ok-variant="danger"
      ok-title="Delete Permission Set"
      modal-class="modal-danger"
      centered
      title="Confirm Delete Permission Set"
      @ok="confirmDeletePermissionSet()"
    >
      <div v-if="permissionSetToDelete !== null">
        Are you sure you want to permission set {{ permissionSetToDelete.PermissionSetName }}?
      </div>
    </b-modal>
    <b-modal
      id="modal-delete-permissionset-still-active-roles"
      ok-only
      ok-title="OK"
      modal-class="modal-danger"
      centered
      title="Cannot Delete Permission Set"
    >
      <div v-if="permissionSetToDelete !== null">
        Permission set {{ permissionSetToDelete.PermissionSetName }} still has {{ permissionSetToDelete.Roles.length }} active role(s). Remove the associated roles before deleting.
      </div>
    </b-modal>
  </div>

</template>

<script>
import {
  BBreadcrumb, BAvatar, BBadge, BPagination, BFormGroup, BFormInput, BFormSelect, BDropdown, BDropdownItem, BInputGroupPrepend, BInputGroup, BButton, BModal
} from 'bootstrap-vue'
import { VueGoodTable } from 'vue-good-table'
import 'vue-good-table/dist/vue-good-table.css'
import Ripple from 'vue-ripple-directive'
import { required } from '@validations'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import APIService from "@core/utils/APIService";

const apiService = new APIService();

import axios from 'axios'

import store from '@/store/index'
// import { mapState } from 'vuex'

export default {
  components: {
    VueGoodTable,
    BAvatar,
    BBadge,
    BPagination,
    BFormGroup,
    BFormInput,
    BFormSelect,
    BDropdown,
    BDropdownItem,
    BBreadcrumb,
    BButton,
    BModal,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      cookie: '',
      searchVisible: true,
      pageLength: 100,
      dir: false,
      userRole: '',
      description: '',
      permissionsSets: '',
      permissionSetData: [],
      permissionSetToDelete: null,
      columns: [
        {
          label: 'Permission Set',
          field: 'PermissionSetName',
        },
        {
          label: 'User Roles',
          field: 'UserRolesDisplay',
        },

        {
          label: 'Action',
          field: 'action',
        },
      ],
      rows: [],
      searchTerm: '',
      status: [{
        1: 'Current',
        2: 'Professional',
        3: 'Rejected',
        4: 'Resigned',
        5: 'Applied',
      },
      {
        1: 'light-primary',
        2: 'light-success',
        3: 'light-danger',
        4: 'light-warning',
        5: 'light-info',
      }],
      breadcrumbItems: [
        {
          text: 'Admin',
          href: '#'
        },
        {
          text: 'Users & Permissions',
          href: '/admin/users-and-permissions'
        },
        {
          text: 'Permission Sets',
          active: true,
        },
      ],
      required,
    }
  },
  computed: {
    statusVariant() {
      const statusColor = {
        /* eslint-disable key-spacing */
        Current      : 'light-primary',
        Professional : 'light-success',
        Rejected     : 'light-danger',
        Resigned     : 'light-warning',
        Applied      : 'light-info',
        /* eslint-enable key-spacing */
      }

      return status => statusColor[status]
    },
    direction() {
      if (store.state.appConfig.isRTL) {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.dir = true
        return this.dir
      }
      // eslint-disable-next-line vue/no-side-effects-in-computed-properties
      this.dir = false
      return this.dir
    },
  },
  created() {
    this.refreshPermissionSets();
  },
  methods: {
    refreshPermissionSets() {
      apiService.get('permissions/permissionsets?max=1000').then(resp => {
        this.permissionSetData = resp.data;
        var rows = resp.data.map(data => ({
          PermissionSetName: data.Name, ID: data.ID,
          UserRolesDisplay: data.Roles ? data.Roles.map(r => r.Name).join(', ') : '',
          Roles: data.Roles
        }))
        this.rows = rows;
      }).catch(error => error)
    },
    openAddPermSetForm() {
      this.$router.push('/admin/users-and-permissions/permission-sets/add')
    },
    editPermissionSet(pSet) {
      this.$router.push('/admin/users-and-permissions/permission-sets/edit/' + pSet.ID);
    },
    copyPermissionSet(pSet) {
      this.$router.push('/admin/users-and-permissions/permission-sets/copy/' + pSet.ID);
    },
    deletePermissionSetWithPrompt(pSet) {
      this.permissionSetToDelete = pSet;
      if (this.permissionSetToDelete.Roles.length > 0) {
        this.$bvModal.show('modal-delete-permissionset-still-active-roles');
        return;
      }
      this.$bvModal.show('modal-delete-permissionset');
    },
    confirmDeletePermissionSet() {
      if (!this.permissionSetToDelete) { return; }
      apiService.delete('permissions/permissionset/' + this.permissionSetToDelete.ID).then(resp => {
        if (resp) {
          this.showToast('success', 'top-center', 4000, 'Permission Set deleted');
          this.refreshPermissionSets();
        }
      });
      this.permissionSetToDelete = null;
    },
    showToast(variant, position, timeout, data) {
      this.$toast({
        component: ToastificationContent,
        props: {
          title: 'Notification',
          icon: 'InfoIcon',
          text: data,
          variant,
        },
      },
      {
        position,
        timeout,
      })
    },
  },
}
</script>

<style>
/* overide classes just for this view better pattern than inline styles on bootstrap components aj */
.btn--add-users , .input-group-text{
  border-radius: 0;
}

[dir] .form-control, [dir] .vs--searchable .vs__dropdown-toggle{
    /* border: none; */
    border-radius: 0px;
     height: 2.78rem;
}
</style>
